import { useDeleteFileMutation } from "apollo/hooks/mutations";
import {
  FILE_TABLE_ACTION_SYNC_VERSION,
  QUERY_PROGRESS_FAILED,
  QUERY_PROGRESS_PENDING,
  QUERY_PROGRESS_SUCCEED,
} from "core/consts";
import { FileParams, Filev2, QueryProgress } from "core/types";
import {
  GeneralListAction,
  SetAction,
} from "ds_legacy/components/Tables/GeneralTable/MenuActions";
import { useToast } from "dsl/atoms/ToastNotificationContext";
import { Dispatch, SetStateAction, useCallback } from "react";
import { useTranslations } from "translations";
import { FileFeatureData } from "./useFilesFeature";

export function useDeleteFile({
  action,
  params,
  setAction,
  setProgress,
}: {
  action: GeneralListAction<Filev2> | undefined;
  params: FileParams;
  setAction: SetAction<FileFeatureData>;
  setProgress: Dispatch<SetStateAction<QueryProgress>>;
}) {
  const translations = useTranslations();
  const toast = useToast();
  const [deleteFile, deleteFileProgress] = useDeleteFileMutation({
    id: action?.data.id ?? -1,
    params,
    onCompleted: () => {
      if (action?.type !== FILE_TABLE_ACTION_SYNC_VERSION) {
        toast({
          message: translations.fileSection.fileDeleteModal.toastSuccess,
          color: "success",
        });
        setAction(undefined);
        setProgress(QUERY_PROGRESS_SUCCEED);
      }
    },
    onError: (err) => {
      toast({ message: translations.fileSection.toastError, color: "danger" });
      console.log(err);
      setProgress(QUERY_PROGRESS_FAILED);
    },
    refetchQueries: ["getFiles"],
  });

  const handleDeleteFile = useCallback((id?: number) => {
    setProgress(QUERY_PROGRESS_PENDING);

    return id ? deleteFile(undefined, { id, params }) : deleteFile();
  }, []);

  return { handleDeleteFile, deleteFileProgress };
}
