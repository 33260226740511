import {
  Backdrop,
  Button,
  Chip,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListSubheader,
  Paper,
  SxProps,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { TRACK_EVENTS } from "core/consts";
import { ONTOLOGY_FILE_CATEGORY } from "core/model/utils/ontologies";
import { useGetOntology } from "core/model/utils/ontologies/hooks";
import { getKey } from "core/model/utils/strings";
import Tooltip from "ds_legacy/components/Tooltip";
import { GREY_600, TEXT_BODY } from "ds_legacy/materials/colors";
import {
  APP_BAR_HEIGHT,
  TABS_HEIGHT_MOBILE,
  Z_INDEX_FILE_UPLOAD_STATES,
  Z_INDEX_FILE_UPLOAD_STATES_BACKDROP,
  dp,
  getPatientPageOffset,
  margin,
  padding,
} from "ds_legacy/materials/metrics";
import {
  FONT_SIZE_12,
  FONT_SIZE_16,
  LINE_HEIGHT_24,
} from "ds_legacy/materials/typography";
import { useMedia } from "dsl/atoms/ResponsiveMedia";
import { useHasOverFlown } from "dsl/hooks";
import { useWarnBeforeExit } from "dsl/hooks/useWarnBeforeExit";
import { useWarnBeforeNavigate } from "dsl/hooks/useWarnBeforeNavigate";
import {
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  CircleAlertIcon,
  XIcon,
} from "lucide-react";
import {
  CSSProperties,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useMatch } from "react-router-dom";
import { useTracking } from "react-tracking";
import styled from "styled-components";
import { useTranslations } from "translations";
import { FileUploadState, FileUploadStates } from "../useUploadFile";

const SENDER_DASHBOARD_ROUTE_REGEX = /^\/app\/clinic\/dashboard.*/;
const RECEIVER_DASHBOARD_ROUTE_REGEX = /^\/careprovider\/dashboard\/\d+\/.*/;

const FONT_STYLE_TITLE: CSSProperties = {
  fontSize: FONT_SIZE_16,
  lineHeight: LINE_HEIGHT_24,
};

const FONT_STYLE: CSSProperties = {
  fontSize: FONT_SIZE_16,
  lineHeight: LINE_HEIGHT_24,
};

const positionPatientFiles: SxProps = {
  bottom: 0,
  position: "fixed",
  right: 0,
  width: dp(412),
};

const positionPatientFilesTablet: SxProps = {
  left: 0,
  position: "fixed",
  right: 0,
  top: getPatientPageOffset({ offsetFrom: "appBar" }),
  width: "100%",
};

const positionMessenger: SxProps = {
  bottom: 0,
  left: "50%",
  position: "fixed",
  transform: "translateX(-50%)",
  WebkitTransform: "translateX(-50%)",
  width: dp(412),
};

const positionMessengerTablet: SxProps = {
  left: 0,
  position: "fixed",
  right: 0,
  top: `calc(${dp(APP_BAR_HEIGHT)} + ${TABS_HEIGHT_MOBILE})`,
  width: "100%",
};

const IconButtonWrapper = styled.div<{ small?: boolean }>`
  width: ${({ small }) => dp(small ? 13 : 24)};
  height: ${({ small }) => dp(small ? 13 : 24)};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IconButtonPlaceholder = IconButtonWrapper;

function useFileUploadStates({ files }: { files: FileUploadState[] }) {
  const translations = useTranslations();
  const [loadingCount, setLoadingCount] = useState(0);
  const [errorCount, setErrorCount] = useState(0);
  const [cancelledCount, setCancelledCount] = useState(0);
  const [successCount, setSuccessCount] = useState(0);
  const [title, setTitle] = useState("");

  useEffect(() => {
    setLoadingCount(files.filter(({ loading }) => loading).length);
    setErrorCount(
      files.filter(({ error, isCancelled }) => error && !isCancelled).length,
    );
    setCancelledCount(files.filter(({ isCancelled }) => isCancelled).length);
    setSuccessCount(
      files.filter(
        ({ error, isCancelled, loading }) => !loading && !error && !isCancelled,
      ).length,
    );
  }, [files]);

  useEffect(() => {
    const value = [];
    if (loadingCount)
      value.push(
        translations.fileSection.fileUploadStates.loading({
          numberOfFiles: `${loadingCount}`,
        }),
      );
    if (errorCount)
      value.push(
        translations.fileSection.fileUploadStates.error({
          numberOfFiles: `${errorCount}`,
        }),
      );
    if (cancelledCount)
      value.push(
        translations.fileSection.fileUploadStates.cancelled({
          numberOfFiles: `${cancelledCount}`,
        }),
      );
    if (successCount)
      value.push(
        translations.fileSection.fileUploadStates.success({
          numberOfFiles: `${successCount}`,
        }),
      );

    setTitle(value.join(", "));
  }, [loadingCount, errorCount, cancelledCount, successCount, translations]);

  return { title, loadingCount };
}

function FileUploadStatusListItem({ file }: { file: FileUploadState }) {
  const theme = useTheme();
  const { trackEvent } = useTracking();
  const translations = useTranslations();
  const getOntology = useGetOntology();
  const { cancel, data, error, fileId, isCancelled, loading } = file;
  const { hasOverFlown, ref } = useHasOverFlown<HTMLSpanElement>({
    direction: "x",
    value: data.fileName,
  });

  let fileNameShort: string[] | string = data.fileName.split(".");
  const fileExtension = fileNameShort.pop();
  fileNameShort = fileNameShort.join();

  function handleCancel() {
    trackEvent({
      name: TRACK_EVENTS.PENDING_UPLOAD_CANCEL_CLICKED,
      file_implementation: "HIS",
    });
    cancel();
  }

  return (
    <ListItem disableGutters data-testid={`file-upload-status-${fileId}`}>
      <div
        style={{
          alignItems: "center",
          boxSizing: "border-box",
          display: "flex",
          gap: padding(4),
          padding: padding(2, 2.5, 2, 1.5),
          width: "100%",
        }}
      >
        <Tooltip
          title={getOntology({
            key: data.category,
            type: ONTOLOGY_FILE_CATEGORY,
          })}
        >
          <Chip
            variant="outlined"
            label={getOntology({
              key: data.category,
              type: ONTOLOGY_FILE_CATEGORY,
              useShortValues: true,
            })}
            style={{
              height: dp(24),
              color: GREY_600,
              fontSize: FONT_SIZE_12,
            }}
          />
        </Tooltip>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            flexGrow: 1,
            minWidth: dp(1),
            margin: margin(0, 2, 0, -2),
          }}
        >
          <div
            style={{
              display: "flex",
              flexGrow: 1,
              minWidth: dp(1),
            }}
          >
            <Typography
              ref={ref}
              sx={{
                ...FONT_STYLE,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                width: dp(316),
              }}
            >
              {hasOverFlown ? fileNameShort : data.fileName}
            </Typography>
            {hasOverFlown && (
              <Typography sx={{ ...FONT_STYLE, marginLeft: dp(-1) }}>
                {fileExtension}
              </Typography>
            )}
          </div>
          {(error || isCancelled) && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: padding(0.5),
              }}
            >
              <IconButtonWrapper small>
                <CircleAlertIcon
                  style={{
                    width: "inherit",
                    height: "inherit",
                    color: theme.palette.error.main,
                  }}
                />
              </IconButtonWrapper>
              <Typography
                sx={{
                  ...FONT_STYLE,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  width: dp(316),
                  color: "error.main",
                }}
              >
                {isCancelled
                  ? translations.fileSection.fileUploadStates.uploadStopped
                  : translations.fileSection.fileUploadStates.errorRetryPrompt}
              </Typography>
            </div>
          )}
        </div>

        {loading ? (
          <>
            <IconButtonWrapper>
              <CircularProgress size={24} data-testid="spinner" />
            </IconButtonWrapper>
            <IconButtonWrapper>
              <IconButton
                aria-label={translations.actions.cancel}
                onClick={handleCancel}
              >
                <XIcon size={FONT_SIZE_16} />
              </IconButton>
            </IconButtonWrapper>
          </>
        ) : error || isCancelled ? (
          <>
            <IconButtonPlaceholder />
            <IconButtonPlaceholder />
          </>
        ) : (
          <>
            <IconButtonPlaceholder />
            <IconButtonWrapper>
              <CheckCircleIcon
                data-testid="success-icon"
                aria-label={translations.general.success}
                style={{
                  width: "inherit",
                  height: "inherit",
                  color: theme.palette.success.main,
                }}
              />
            </IconButtonWrapper>
          </>
        )}
      </div>
    </ListItem>
  );
}

export default function FileUploadStatusPanel({
  fileUploadStates = {},
  setFileUploadStates,
}: {
  fileUploadStates: FileUploadStates;
  setFileUploadStates: Dispatch<SetStateAction<FileUploadStates>>;
}) {
  const isAuctionReponse = useMatch(
    "app/clinic/dashboard/patient/:patient_id/auction/:auction_id/request/:request_id",
  );
  const isAuctionRequest = useMatch(
    "careprovider/dashboard/:careprovider_id/requests/:request_id",
  );
  // Accessing the route parameters
  const { request_id } = isAuctionRequest?.params || {};
  const isRequestIdNumber = /^\d+$/.test(request_id ?? "");

  const { isDesktop, isMobile, isTablet } = useMedia();
  const isPatientTablet = !isAuctionReponse && !isRequestIdNumber && !isDesktop;

  const translations = useTranslations();
  const files = Object.values(fileUploadStates);
  const { loadingCount, title } = useFileUploadStates({ files });
  const totalFileCount = files.length;
  const [isOpen, setOpen] = useState(!!totalFileCount);
  const [isMinimized, setMinimized] = useState(false);
  const showBackdrop = isTablet && isOpen && !isMinimized;
  const { hasOverFlown, ref } = useHasOverFlown<HTMLSpanElement>({
    direction: "x",
    value: title,
  });

  function handleMinimize() {
    setMinimized((prev) => !prev);
  }

  function handleClose() {
    setOpen(false);
    setFileUploadStates({});
  }

  async function cancelAll() {
    await Promise.all(
      Object.values(fileUploadStates).map(({ cancel }) => {
        return cancel();
      }),
    );
  }

  const getPositionSx = useCallback(() => {
    if (isAuctionReponse || isRequestIdNumber) {
      return isTablet ? positionMessengerTablet : positionMessenger;
    }

    return isTablet ? positionPatientFilesTablet : positionPatientFiles;
  }, [isAuctionReponse, isRequestIdNumber, isTablet]);

  useEffect(() => {
    setOpen(!!totalFileCount);
  }, [totalFileCount]);

  useWarnBeforeExit({ skip: loadingCount === 0 });
  useWarnBeforeNavigate({
    onConfirm: cancelAll,
    skip: loadingCount === 0,
    skipPaths: [SENDER_DASHBOARD_ROUTE_REGEX, RECEIVER_DASHBOARD_ROUTE_REGEX],
  });

  if (!isOpen) return null;

  return (
    <>
      <Paper
        sx={{
          ...getPositionSx(),
          height: isOpen ? undefined : dp(0),
          padding: 0,
          zIndex: Z_INDEX_FILE_UPLOAD_STATES,
        }}
      >
        <List
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            padding: 0,
            position: "static", // fix Safari
          }}
          subheader={
            <ListSubheader
              sx={{
                position: "static", // fix Safari
                alignItems: "center",
                bgcolor: "secondary.light",
                color: "common.black",
                display: "flex",
                height: dp(72),
                justifyContent: "space-between",
                padding: padding(5 / 8, 1, 5 / 8, 20 / 8),
              }}
            >
              <Tooltip title={hasOverFlown ? title : undefined} placement="top">
                <Typography
                  ref={ref}
                  style={{
                    ...FONT_STYLE_TITLE,
                    width: "100%",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  {title}
                </Typography>
              </Tooltip>

              {loadingCount === 0 ? (
                <Button
                  onClick={handleClose}
                  style={{ marginLeft: margin(1) }}
                  variant="text"
                  sx={{
                    textTransform: "unset",
                    ...FONT_STYLE_TITLE,
                    color: TEXT_BODY,
                  }}
                >
                  {translations.actions.close}
                </Button>
              ) : isDesktop ? (
                <IconButton
                  aria-label={
                    isMinimized
                      ? translations.actions.expand
                      : translations.actions.minimize
                  }
                  onClick={handleMinimize}
                  color="inherit"
                  sx={{ marginLeft: margin(1) }}
                >
                  {isMinimized ? (
                    <ChevronUpIcon size={16} />
                  ) : (
                    <ChevronDownIcon size={16} />
                  )}
                </IconButton>
              ) : null}
            </ListSubheader>
          }
        >
          {/* Body */}

          <div
            style={{
              maxHeight: isMinimized ? dp(0) : dp(250),
              overflowY: "auto",
              transition: "height ease-in-out 0.1s",
              width: "100%",
            }}
          >
            {files.map((file, i) => {
              return <FileUploadStatusListItem file={file} key={getKey(i)} />;
            })}
          </div>
        </List>
      </Paper>
      {showBackdrop && (
        <Backdrop
          sx={{
            bgcolor: GREY_600,
            opacity: isMobile ? "1 !important" : "0.6 !important",
            zIndex: Z_INDEX_FILE_UPLOAD_STATES_BACKDROP,
            top: isPatientTablet
              ? getPatientPageOffset({ offsetFrom: "appBar" })
              : 0,
          }}
          open={true}
        />
      )}
    </>
  );
}
