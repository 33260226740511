import {
  FILE_TABLE_ACTION_SYNC_VERSION,
  QUERY_PROGRESS_FAILED,
  QUERY_PROGRESS_NOT_STARTED,
  QUERY_PROGRESS_PENDING,
  QUERY_PROGRESS_SUCCEED,
} from "core/consts";
import { onPremHttpRequest } from "core/model/careseekers";
import {
  getFileMimeType,
  processDocumentFile,
  validateFile,
} from "core/model/files";
import {
  FileHIS,
  FileHISStateful,
  Filev2Stateful,
  QueryProgress,
} from "core/types";
import {
  GeneralListAction,
  SetAction,
} from "ds_legacy/components/Tables/GeneralTable/MenuActions";
import { useHL7ImportStatusContext } from "dsl/atoms/HL7ImportStatusContext";
import { useToast } from "dsl/atoms/ToastNotificationContext";
import { useDeleteFile } from "dsl/ecosystems/PatientFiles/useDeleteFile";
import { FileFeatureData } from "dsl/ecosystems/PatientFiles/useFilesFeature";
import { useUploadFile } from "dsl/ecosystems/PatientFiles/useUploadFile";
import { useEffect, useState } from "react";
import { useTracking } from "react-tracking";
import { useTranslations } from "translations";

// #TODO: could BE return an empty array instead?
const NULL_MESSAGE = "could not find any documents";

function extractFilenameFromHeader(response: Response) {
  const filenamePattern = /filename=([^;]+)/;

  const contentDisposition = response.headers.get("Content-Disposition");
  const match = contentDisposition?.match(filenamePattern);

  const fileName = match?.[1].trim();
  if (!fileName) {
    throw new Error("[extractFilenameFromHeader] filename not found in header");
  }
  return fileName;
}

export function useImportFilesHIS({
  dispatchOnMount,
  externalId,
  fromArchive,
}: {
  dispatchOnMount?: boolean;
  externalId: string;
  fromArchive?: boolean;
}) {
  const queryParams = `from_archive=${Boolean(fromArchive)}`;
  const [queryProgress, setQueryProgress] = useState<QueryProgress>(
    QUERY_PROGRESS_NOT_STARTED,
  );
  const [files, setFiles] = useState<FileHIS[]>();
  const { on_premise_authorization_token, on_premise_domain_ssl } =
    useHL7ImportStatusContext();

  async function fetchFiles() {
    setQueryProgress(QUERY_PROGRESS_PENDING);
    try {
      const response = await onPremHttpRequest({
        on_premise_domain_ssl,
        path: `patients/${externalId}/documents?${queryParams}`,
        method: "GET",
        on_premise_authorization_token,
      });
      if (response.ok) {
        const payload = await response.json();
        const isEmpty = !payload || payload === NULL_MESSAGE;
        setFiles(isEmpty ? [] : payload);
        setQueryProgress(QUERY_PROGRESS_SUCCEED);
      } else {
        console.error("[useImportFilesHIS]: Import error");
        setQueryProgress(QUERY_PROGRESS_FAILED);
      }
    } catch (err) {
      console.error("Unexpected import error", err);
      setQueryProgress(QUERY_PROGRESS_FAILED);
    }
  }

  useEffect(() => {
    if (dispatchOnMount) {
      fetchFiles();
    }
  }, []);

  return { queryProgress, files, fetchFiles };
}

export function useImportAndUploadFilesContentHIS({
  action,
  externalId,
  filesHIS,
  handleDeleteFile,
  handleUploadFile,
  setAction,
  skip,
}: {
  action: GeneralListAction<FileFeatureData | undefined> | undefined;
  externalId: string;
  filesHIS: FileHISStateful[] | undefined;
  handleDeleteFile: ReturnType<typeof useDeleteFile>["handleDeleteFile"];
  handleUploadFile: ReturnType<typeof useUploadFile>["handleUploadFile"];
  setAction: SetAction<FileFeatureData>;
  skip: boolean;
}) {
  const toast = useToast();
  const translations = useTranslations();
  const { trackEvent } = useTracking();

  const { on_premise_authorization_token, on_premise_domain_ssl } =
    useHL7ImportStatusContext();

  async function fetchFilesDetails({
    filesHIS,
  }: {
    filesHIS: (Pick<
      FileHISStateful,
      | "document_category_number"
      | "document_category_string"
      | "document_id"
      | "id"
      | "share_mode"
    > & { fileV2Id?: Filev2Stateful["id"] })[];
  }) {
    try {
      await Promise.all(
        filesHIS.map(
          async ({
            document_category_number,
            document_category_string,
            document_id,
            fileV2Id,
            id,
            share_mode,
          }) => {
            const response = await onPremHttpRequest({
              on_premise_domain_ssl,
              path: `patients/${externalId}/documents/${document_id}`,
              method: "GET",
              on_premise_authorization_token,
            });

            if (!response.ok) {
              throw new Error(
                `Failed to fetch file: ${response.status} ${response.statusText}`,
              );
            }

            const document_file_name = extractFilenameFromHeader(response);

            const blob = await response.blob();

            const type = getFileMimeType({
              filename: document_file_name,
            });
            const file = new File([blob], document_file_name, {
              type,
            });

            await validateFile({
              file,
              fromArchive: true,
              trackEvent,
              translations,
            });
            const content = await processDocumentFile(file);

            await handleUploadFile({
              category: document_category_number,
              category_other: document_category_string,
              content,
              file,
              fileName: document_file_name,
              kis_document_id_string: id,
              share_mode,
            });
            if (action?.type === FILE_TABLE_ACTION_SYNC_VERSION && fileV2Id) {
              await handleDeleteFile(fileV2Id);
            }
          },
        ),
      );
    } catch (err) {
      console.error("Unexpected import error", err);
      toast({
        color: "danger",
        message: translations.fileUploader.fileCouldNotFetch,
      });
    } finally {
      setAction(undefined);
    }
  }

  useEffect(() => {
    if (!skip && filesHIS?.length) {
      fetchFilesDetails({ filesHIS });
    }
  }, [skip, filesHIS]);
}
